import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';

const Tarifs = ({ pageContext, data }) => {
  const { title, description, prix, prestations } = data.prestationsTarifsJson;
  return (
    <Layout callBtn={false}>
      <div className="bg-gray-100">
        <div className="pt-12 sm:pt-16 lg:pt-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="font-extrabold text-gray-900 text-3xl lg:text-4xl xl:text-5xl">
                Tarifs <span className="text-primarycolor">{title}</span> dans
                le canton de{' '}
                <span className="text-primarycolor">{pageContext.canton}</span>
              </h2>
              {/* <p className="mt-4 text-xl text-primarycolor">
              If you're not satisfied, contact us within the first 14 days and
              we'll send you a full refund.
            </p> */}
            </div>
          </div>
        </div>
        <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-100"></div>
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                  <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                    {title}
                  </h3>
                  <p className="mt-6 text-base text-gray-500">{description}</p>
                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-primarycolor">
                        CE QUI EST INCLU
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200"></div>
                    </div>
                    <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                      {prestations.map((prestation, index) => (
                        <li
                          key={index}
                          className="flex items-start lg:col-span-1"
                        >
                          <div className="flex-shrink-0">
                            <svg
                              className="h-5 w-5 text-green-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className="ml-3 text-sm text-gray-700">
                            {prestation}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                  <p className="text-lg leading-6 font-medium text-gray-900">
                    prix dès
                  </p>
                  <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                    <span>{prix}</span>
                    <span className="ml-3 text-xl font-medium text-gray-500">
                      CHF
                    </span>
                  </div>
                  <div className="mt-6">
                    <div className="rounded-md shadow">
                      <Link
                        to="/contact"
                        className="flex items-center justify-center px-10 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light hidden md:block"
                      >
                        Contactez-nous
                      </Link>
                      <a
                        href="tel:0791304768"
                        className="flex items-center justify-center px-10 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light block md:hidden"
                      >
                        Appelez-nous
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query query($title: String!) {
    prestationsTarifsJson(title: { eq: $title }) {
      title
      description
      prix
      prestations
    }
  }
`;
export default Tarifs;
